import useInternetConnection from 'hooks/useInternetConnection';
import Cookies from 'js-cookie';
import React from 'react';
import httpService from 'service/httpService';
import { Subscription } from 'service/subscription';
import userService, { User } from 'service/userService';

const defOrgId = userService.getOrgID();

const AppContext = React.createContext({});

const AppContextProvider = ({ children }) => {
  const { isOnline } = useInternetConnection();
  const accessToken = Cookies.get('_access_auth');
  const subscriptionService = new Subscription();

  const [organizationId, setOrganizationId] = React.useState(defOrgId);
  const [refetch, setRefetch] = React.useState(false);
  const [plans, setPlans] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFetchingOrg, setIsFetchingOrg] = React.useState(false);
  const [invitations, setInvitations] = React.useState([]);
  const [organizations, setOrganizations] = React.useState([]);
  const currentPath = location.pathname.split('/')[1];

  React.useEffect(() => {
    setOrganizationId(userService.getOrgID());
  }, [refetch]);

  React.useEffect(() => {
    if (!isOnline) return;
    if (!accessToken) return;
    if (!organizationId) return;
    if (currentPath !== 'dashboard' && currentPath !== 'plans') return;
    getPlans();
  }, [accessToken, refetch, isOnline, organizationId]);

  async function getPlans() {
    try {
      setIsLoading(true);
      const { data } = await subscriptionService.getPlans();
      setPlans(subscriptionService.formatPlans(data?.data));
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }

  return (
    <AppContext.Provider
      value={{
        plans,
        setPlans,
        invitations,
        setInvitations,
        organizations,
        setOrganizations,
        isFetching: isLoading,
        setRefetch,
        isFetchingOrg,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
