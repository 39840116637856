import Cookies from 'js-cookie';
import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';

const SignOut = () => {
  React.useEffect(() => {
    function handleLogout() {
      Cookies.remove('_access_auth');
      Cookies.remove('_refresh_auth');
      localStorage.clear();
      location.replace(PATHNAMES.SIGN_IN + window.location.search);
    }
    handleLogout();
  }, []);
  return null;
};

export default SignOut;
