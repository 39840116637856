import Config from 'config/Config';
import Cookies from 'js-cookie';
import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';
import userService from 'service/userService';

let idleTime = eval(Config.IDLE_DURATION);
const ACCESS_TOKEN = '_access_auth';
const REFRESH_TOKEN = '_refresh_auth';

const useWindowOnIdle = () => {
  const organizationId = userService.getOrgID();
  const LINK = `${PATHNAMES.SIGN_OUT}?redirect_url=${window.location.pathname}&organization_id=${organizationId}`;

  React.useEffect(() => {
    const checkToken = () => {
      const token = Cookies.get(ACCESS_TOKEN);
      if (!token) location.replace(LINK);
    };

    checkToken();
    const interval = setInterval(checkToken, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    let timeout;

    function clearCookies() {
      Cookies.remove(ACCESS_TOKEN);
      Cookies.remove(REFRESH_TOKEN);
      localStorage.clear();
      location.replace(LINK);
    }

    function resetTimer() {
      const token = Cookies.get(ACCESS_TOKEN);
      if (!token) clearCookies();
      else {
        clearTimeout(timeout);
        timeout = setTimeout(clearCookies, idleTime);
      }
    }

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    timeout = setTimeout(clearCookies, idleTime);

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      clearTimeout(timeout);
    };
  }, []);

  return {};
};

export default useWindowOnIdle;
