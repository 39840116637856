// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import httpService from './httpService';
import Config from 'config/Config';
import saveToken from 'utils/saveToken';
import getDeviceDetails from 'utils/getDeviceInfo';
import { PATHNAMES } from 'lib/constants/pathnames';

const firebaseConfig = {
  apiKey: Config?.apiKey,
  authDomain: Config?.authDomain,
  projectId: Config?.projectId,
  storageBucket: Config?.storageBucket,
  messagingSenderId: Config?.messagingSenderId,
  appId: Config?.appId,
  measurementId: Config?.measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const path = '/user/auth/firebase';

const searchParams = new URLSearchParams(window.location.search);
const redirect_url = searchParams.get('redirect_url');
const organization_id = searchParams.get('organization_id');

const platform = {
  type: 'WEB',
  device: `${getDeviceDetails()['os.name']} · ${
    getDeviceDetails()['browser.name']
  }`,
};

async function signIn(data, provider, setIsLoading, isSignedUp) {
  const formData = { accessToken: data?.accessToken, provider, platform };
  if (!formData.accessToken) return;
  try {
    setIsLoading(true);
    const { data } = await httpService.post(path, formData);
    saveToken(data?.data);
    if (isSignedUp) location.replace(PATHNAMES.FEATURE_SELECTION);
    else if (redirect_url && organization_id) {
      localStorage.setItem('organizationId', organization_id);
      location.replace(redirect_url);
    } else location.replace(PATHNAMES.ORGANIZATIONS);
  } catch (ex) {
    setIsLoading(false);
    httpService.errorHandler(ex);
  }
}

function googleSignIn(setIsLoading, isSignedUp = false) {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      signIn(user, 'GOOGLE', setIsLoading, isSignedUp);
    })
    .catch((ex) => {
      const errorMessage = ex.message;
      httpService.errorHandler(errorMessage);
    });
}

function facebookSignIn(setIsLoading, isSignedUp = false) {
  const provider = new FacebookAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      signIn({ accessToken }, 'FACEBOOK', setIsLoading, isSignedUp);
    })
    .catch((ex) => {
      const errorMessage = ex.message;
      httpService.errorHandler(errorMessage);
    });
}

function appleSignIn(setIsLoading, isSignedUp = false) {
  const provider = new OAuthProvider('apple.com');
  signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      signIn(user, 'APPLE', setIsLoading, isSignedUp);
    })
    .catch((ex) => {
      const errorMessage = ex.message;
      httpService.errorHandler(errorMessage);
    });
}

// const analytics = getAnalytics(app);

export { facebookSignIn, googleSignIn, appleSignIn };
export default app;
