import { yupResolver } from '@hookform/resolvers/yup';
import { Button, IconButton, Paper, TextField } from '@material-ui/core';
import Menu from 'lib/components/Menu/Menu';
import BTNLoader from 'lib/components/Preloaders/Btn';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import authValidation from 'validations/auth/authValidation';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Google } from 'lib/assets/icons/google.svg';
import { ReactComponent as Facebook } from 'lib/assets/icons/facebook.svg';
import { ReactComponent as Apple } from 'lib/assets/icons/apple.svg';
import authService from 'service/authService';
import saveToken from 'utils/saveToken';
import httpService from 'service/httpService';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { appleSignIn, googleSignIn } from 'service/firebase';
import getDeviceDetails from 'utils/getDeviceInfo';
import { PATHNAMES } from 'lib/constants/pathnames';
import useUserData from 'hooks/useUserData';

const initialValues = {
  email: '',
  password: '',
};

const authTypes = {
  DEFAULT: 'DEFAULT',
  GOOGLE: 'GOOGLE',
  APPLE: 'APPLE',
};
const SignIn = () => {
  const history = useHistory();
  const { isAuth } = useUserData();
  const [authType, setAuthType] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const redirect_url = searchParams.get('redirect_url');
  const organization_id = searchParams.get('organization_id');

  const { handleSubmit, errors, control } = useForm({
    mode: 'all',
    resolver: yupResolver(authValidation.SIGN_IN),
    defaultValues: initialValues,
  });

  const platform = {
    type: 'WEB',
    device: `${getDeviceDetails()['os.name']} · ${
      getDeviceDetails()['browser.name']
    }`,
  };

  if (isAuth) history.goBack();

  return (
    <div className="container-xxl">
      <Menu />
      <Paper
        className="p-3 py-5 border mx-auto my-4 rounded-4"
        style={{ maxWidth: 600 }}>
        <div className="mb-4">
          <div className="fs-4 text-center">Welcome Back</div>
          <div className="fs-sm text-center">
            Sign in to access your account
          </div>
        </div>
        <form
          className="mx-auto"
          onSubmit={handleSubmit(save)}
          style={{ maxWidth: 350 }}>
          <div className="mb-3">
            <label>
              Email: <span className="text-danger">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              render={(props) => (
                <TextField
                  type="email"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['email']?.message}
                  error={!!errors['email']}
                />
              )}
            />
          </div>
          <div className="mb-5">
            <label>
              Password: <span className="text-danger">*</span>
            </label>
            <Controller
              name="password"
              control={control}
              render={(props) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  type={isPasswordVisible ? 'text' : 'password'}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['password']?.message}
                  error={!!errors['password']}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={changePasswordVisibility}>
                        {isPasswordVisible ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <Button
              fullWidth
              size="medium"
              variant="contained"
              color="primary"
              type="submit"
              style={{ textTransform: 'none' }}
              disabled={isLoading}
              startIcon={
                isLoading && authType === authTypes.DEFAULT && <BTNLoader />
              }>
              Sign In
            </Button>
          </div>
          <div className="text-center mb-4">
            Forgot password?{' '}
            <Link className="text-primary" to={PATHNAMES.FORGOT_PASSWORD}>
              Click here
            </Link>
          </div>
          <div className="text-center mb-4">Or</div>
          <div className="mb-3">
            <Button
              fullWidth
              size="medium"
              variant="outlined"
              type="button"
              disabled={isLoading}
              onClick={handleGoogleSignIn}
              style={{ textTransform: 'none' }}
              startIcon={<Google />}
              endIcon={
                isLoading && authType === authTypes.GOOGLE && <BTNLoader />
              }>
              Sign in with Google
            </Button>
          </div>
          <div className="mb-3">
            <Button
              fullWidth
              size="medium"
              variant="outlined"
              type="button"
              disabled={isLoading}
              onClick={handleAppleSignIn}
              className="bg-dark text-white"
              style={{ textTransform: 'none' }}
              endIcon={
                isLoading &&
                authType === authTypes.APPLE && <BTNLoader color="#fff" />
              }
              startIcon={<Apple />}>
              Sign in with Apple
            </Button>
          </div>
          <div className="text-center my-2 mb-4">
            Don't have an account?{' '}
            <Link className="text-primary" to={PATHNAMES.SIGN_UP}>
              Sign Up
            </Link>
          </div>
        </form>
      </Paper>
    </div>
  );

  async function save(formData) {
    try {
      setAuthType(authTypes.DEFAULT);
      setIsLoading(true);
      const { data } = await authService.login({ ...formData, platform });
      saveToken(data?.data);
      if (redirect_url && organization_id) {
        localStorage.setItem('organizationId', organization_id);
        location.replace(redirect_url);
      } else location.replace(PATHNAMES.ORGANIZATIONS);
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }

  function handleGoogleSignIn() {
    setAuthType(authTypes.GOOGLE);
    googleSignIn(setIsLoading);
  }
  function handleAppleSignIn() {
    setAuthType(authTypes.APPLE);
    appleSignIn(setIsLoading);
  }

  function changePasswordVisibility() {
    setIsPasswordVisible((prev) => !prev);
  }
};

export default SignIn;
