import { Route, Redirect } from 'react-router-dom';
import { UserContext } from 'context/user/user';
import React from 'react';
import useWindowOnIdle from 'hooks/useWindowOnIdle';
import { PATHNAMES } from 'lib/constants/pathnames';
import userService from 'service/userService';

export const PrivateRoute = ({
  component: PrivateComponent,
  ...otherProps
}) => {
  useWindowOnIdle();
  const organizationId = userService.getOrgID();
  const { accessToken } = React.useContext(UserContext);

  const SEARCH_PARAMS = `?redirect_url=${window.location.pathname}&organization_id=${organizationId}`;

  return (
    <Route
      {...otherProps}
      render={(props) =>
        !!accessToken ? (
          <PrivateComponent {...props} />
        ) : (
          <Redirect
            to={{ pathname: PATHNAMES.SIGN_OUT, search: SEARCH_PARAMS }}
            {...otherProps}
          />
        )
      }
    />
  );
};
